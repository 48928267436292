import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { H1, Paragraph, ParagraphBold } from 'components/common/Styles';

import { ContentArticle820 } from 'components/Content';
import Footer from 'components/Footer/Footer';
import Seo from 'components/seo';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';

const Disclaimer = () => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};
	return (
		<>
			<Seo
				title='Content Disclaimer'
				description='Find out why we created BookPhysio.com, an on-demand physiotherapy booking site that connects patients with experienced physiotherapists.'
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<ContentArticle820 isOpen={isMenuOpen}>
						<TopPadding />
						<Paragraph />
						<H1>Content Disclaimer</H1>
						<Paragraph>
							BookPhysio.com is a digital publisher and does not offer personal health or medical advice.
							If you're facing a medical emergency, call your local emergency services immediately or
							visit the nearest emergency room or hospital. Before starting any nutrition, diet, exercise,
							fitness, medical, or wellness program, you should consult your healthcare provider.
						</Paragraph>
						<Paragraph>
							This content, developed through collaboration with licensed medical professionals and
							external contributors, including text, graphics, images, and other material contained on the
							website, apps, newsletter, and products ("Content"), is general in nature and for
							informational purposes only and does not constitute medical advice.
						</Paragraph>
						<ParagraphBold>
							This Content is not intended to be a substitute for professional medical advice, diagnosis,
							or treatment.
						</ParagraphBold>
						<Paragraph>
							Always consult your doctor or other qualified healthcare provider with any questions you may
							have regarding a medical condition, procedure, or treatment, whether it is a prescription
							medication, over-the-counter drug, vitamin, supplement, or herbal alternative.
							BookPhysio.com makes no guarantees about the efficacy or safety of products or treatments
							described on BookPhysio.com's Content.
						</Paragraph>
						<Paragraph>
							Health conditions and drug information contained are subject to change and are not intended
							to cover all possible uses, directions, precautions, warnings, drug interactions, allergic
							reactions, or adverse effects. BookPhysio.com does not recommend or endorse any specific
							test, clinician, clinical care provider, product, procedure, opinion, service, or other
							information that may be mentioned in BookPhysio.com's websites and Content.
						</Paragraph>
						<Paragraph>
							The disclaimers herein are provided on this page for ease of reference. This disclaimer
							supplements and is a part of BookPhysio.com's{' '}
							<Link to='/terms-and-conditions'>{`Terms & Conditions`}</Link>.
						</Paragraph>
					</ContentArticle820>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const TopPadding = styled.div`
	width: 100%;
	height: 50px;

	@media (max-width: 767px) {
		display: none;
	}
`;

export default Disclaimer;
